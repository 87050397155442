<template>
  <div class="wrapper-popup">
    <div class="overlay" />
    <div class="container-popup"  style="color:#4D4D4D;line-height:18px;font-size: 14.5px;">
      <div class=" mx-4 "> <slot /></div>
      <div class="flex flex-row justify-center mt-4 ml-4 px-0.5">
       
        <button
          @click="goToIndex()"
          class="bg-jublia-blue text-white text-base font-bold rounded-lg px-4 py-0 mr-4"
        >
         ok
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  methods: {
    goToIndex() {
      this.$emit('close')
     this.$emit("update:is-open", false);
    //  console.log('cvvvvv')
    },
   
   
  }
}
</script>

<style scoped>
.wrapper-popup {
  @apply fixed top-0 left-0 w-full h-full flex items-end px-4 pb-10;
  overflow-y: hidden;
  z-index: 100;
}

.container-popup {
  @apply w-full bg-white rounded-lg border-jublia-purple text-center py-6 z-10;
  max-width: 900px;
  margin: auto;
}

.overlay {
  @apply fixed top-0 left-0 w-full h-screen bg-jublia-bluest opacity-75;
}
 button{
    width: 177px;
    height: 38px;
    font-size: 1rem;
    line-height: 13px;
  }
</style>
