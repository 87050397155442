<template>
  <div class="wrapper">
    <div class="container relative">
      <div class="h-screen flex flex-col justify-center items-center">
        <div class="relative w-96 flex flex-col justify-center items-center mt-6" style="width: 24rem; max-width: 24rem;">
        <img
          v-if="i18n.getLocale() === 'en'"
          src="@/assets/img/Jublia_logo-en.png"
          alt="Logo Jublia"
          
          height="212"
          style="margin-left:.8rem; width:200px"
           class="place-self-start"

        />
        <img
          v-if="i18n.getLocale() === 'fr'"
          src="@/assets/img/Jublia_logo-fr.png"
          alt="Logo Jublia"
         
          height="212"
          style="margin-left:.8rem; width:200px"
          class="place-self-start"
        />
        
         <img
            src="@/assets/img/bottle_din_masked.png"
            alt="Bottle Jublia"
            width="95"
            height="255"
             style="margin-left:.8rem"
            class="bottle  mb-8"
          />
       <p
         @click="$i18n.setLocale($i18n.getLocale() === 'fr' ? 'en' : 'fr');"
          style="border:1px solid #522E91;  border-radius: 30px; font-size:12pt;  display: inline-block;width: 211px; text-align: center;"
          class="block text-base no-underline  cursor-pointer place-self-start "
        >
          {{ $t('form-din.setLocale') }}
        </p>
        </div>
        <span 
          class="my-10 px-6 py-2 bg-jublia-purple rounded-full text-base text-white place-self-start hidden "
          >{{ $t('form-din.experience') }}</span>
        <div
         
          class="relative flex flex-col w-full mt-24"
        >
          <jublia-p  style="font-family: DINNextLTPro-Bold;" >{{ $t('form-din.enterDin') }}</jublia-p>
         <img
            src="@/assets/img/bottle_din_masked.png"
            alt="Bottle Jublia"
            width="95"
            height="256"
            class="bottle1 hidden "
          />
          <input
            v-model="din" 
            type="number"
            class="w-full border border-jublia-blue h-12 rounded-lg mt-2 px-4 text-xl"
          />
          <div
           ref="errore"
           v-if="errore"
            class="mt-6 border border-red-500 px-4 py-1 mt-2"
          >
           error 
          </div>

          <jublia-button
          :bdgray="din.length ? false : true"
          v-on:click="dinLogin()"
          :disable="din.length ? false : true"
             style="text-transform: uppercase;"
            class="relative mt-2 border-jublia-blue"
            
          >
         {{ $t('form-din.valider') }}
          </jublia-button>
          <transition name="slide">
          <pop v-if="popup"  v-model:is-open="popup"  @close="close()" :token="token"/>
          </transition>
         <p style='margin:0 auto;'>{{ $t('form-din.or') }} </p>
          
           <jublia-button
             v-on:click="popup=true"
            :disabled="isLoading"
            style="text-transform: uppercase;"
            class="relative  border-jublia-blue"
            type="submit"
          >
            {{ $t('form-din.login') }}
          </jublia-button>
       
        
        </div>
        
        <!--<jublia-popup-end  v-if="popupc" v-model:is-open="popupc"  @close="closec()">
          <jublia-p  v-html="$t('form-din.cookie')" style="text-align: center!important;" class="w-full text text2" />
        </jublia-popup-end>-->
      </div>
    </div>
  </div>
</template>
<script>
 import JubliaP from '@/components/text/jublia-p'
  import pop from '@/components/back-pop/back-pop'
import JubliaPopupEnd from '@/components/popup-co/popup-end'

import JubliaButton from '@/components/button/jublia-button'
  import { useI18n } from "vue3-i18n";
import JubliaHeader from '@/components/header/jublia-header'
import { reactive, ref,computed,watchEffect, onUnmounted,onBeforeMount , nextTick,onMounted,onUpdated} from "vue";

import {useRouter, useRoute   } from "vue-router";
import { useStore} from 'vuex'
//import { storet } from './store'
//import JubliaH1 from '@/components/text/jublia-h1'
export default {

  name: 'about',
  
  components: {
    pop,
 'jublia-button':JubliaButton,
 //JubliaH1,
'jublia-popup-end':JubliaPopupEnd,
 'jublia-header':JubliaHeader,
 'jublia-p':JubliaP
  },

  setup(props, context ) {
    const i18n = useI18n();
    let errore=ref(false)
    let popup=ref(false)
     let popupc=ref(false)
 const token=ref("")
    let errore1=false
      const din=ref("")
     const store= useStore()
      const router  = useRouter()
        const route = useRoute()

  
 onBeforeMount(function() {
   if(route.params.token)popup.value=true;
  
   token.value=route.params.token
    })

     const auth = computed(() => store.getters['login/isAuthenticated'])
      const setLocale = () => {
      i18n.setLocale(i18n.getLocale() === 'fr' ? 'en' : 'fr');
            popup._value=false;


    };
  
     const close = ()=> {
       popup._value=false; 

     router.push({name: 'din'});
      setTimeout(()=>{
     window.location.reload();
      },1000) 

     }
      const closec = ()=> {
       popupc._value=false;
       localStorage.setItem('p','f')
       }
const dinLogin = ()=> {

    store.dispatch('login/loginDin', { din:din.value }).then(()=>{
          if(store.getters['login/isAuthenticated']===false){
                   throw Error('no login')

          }
          if(store.getters['login/isAuthenticated']===true){
          router.push({name: 'video'})
          }
        })
      .catch(e => {
         errore.value=true
        console.log(e)
      })
          

  };
 

    return {
      setLocale,
      token,
      close,
      dinLogin,
      closec,
      din,
      auth,
      errore,
      errore1,
      popupc,
      popup,
      i18n
    };
  },
}

</script>
<style scoped>
.container {
  @media all and (min-width: 767px) {
    max-width: 60rem;
    padding: 0 6rem;
  }
}
.sp {
   @apply text-lg;
    text-transform: uppercase;
   font-family: DINNextLTPro-Bold;
  @media all and (min-width: 100px) and (max-width: 767px) {
    @apply text-4xl !important;
    font-size:44pt;
  }
}
.local {

  @media all and (min-width: 500px) and (max-width: 767px) {
     margin-right: 200px
  }
}
.bottle {
  position: absolute;
  right: 2rem;
top: -6rem;
 @media all and (min-width: 500px) and (max-width: 767px) {
     right: 1rem !important;
  }
}
@media all and (min-width: 500px) and (max-width: 767px) {
  .bottle {
     right: 1rem !important;
  }
  }
.bottle1 {
  position: absolute;
  right: 0;
  top: -6rem;
}
.container {

  @media all and (min-width: 768px) {
   
   min-height: 35rem;
  }
}

</style>
